var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tiny-editor-wrapper",staticClass:"tiny-editor-wrapper"},[(_vm.translatable)?_c('a-form-item',{staticClass:"form-item-holder",attrs:{"validate-status":_vm.validatingStatus,"help":_vm.validateHelp,"html-for":'d1'+ _vm.fieldName}},[_c('template',{slot:"label"},[_c('a-tag',{attrs:{"color":"blue"}},[_vm._v(" "+_vm._s(_vm.formLocaleLang)+" ")]),_c('span',{staticClass:"form-item-label-text"},[_vm._v(_vm._s(_vm.label))])],1),_c('div',{class:{error: _vm.validatingStatus==='error'}},[(_vm.tinyMounted)?_c('editor',{ref:"tm",attrs:{"id":'d1'+ _vm.fieldName,"value":_vm.translatableValue[_vm.lang],"api-key":"meh3qjtt8e71r37qwbm7ckegbm3e00lmwxfrwqt40hho4tf4","init":{
                height: 250,
                min_height: 150,
                menubar: true,
                toolbar2: 'undo redo | fontsizeselect ' +
                  'formatselect | bold italic  strikethrough  forecolor backcolor ' +
                  '| link | alignleft aligncenter alignright alignjustify  ' +
                  '| numlist bullist outdent indent  | removeformat' +
                  '| code | preview fullscreen ',
                plugins: [
                  'advlist autolink template lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                templates: _vm.editorTemplates,
              }},on:{"editorInit":_vm.changeLanguageForUpdateEditor,"input":function (val) { return _vm.onTranslatableValueChange(_vm.lang, val); }}}):_vm._e()],1)],2):_c('a-form-item',{staticClass:"form-item-holder",attrs:{"validate-status":_vm.validatingStatus,"help":_vm.validateHelp,"html-for":'d1'+ _vm.fieldName}},[_c('template',{slot:"label"},[_c('span',{staticClass:"form-item-label-text"},[_vm._v(_vm._s(_vm.label))])]),_c('div',{class:{error: _vm.validatingStatus==='error'}},[(_vm.tinyMounted)?_c('editor',{ref:"tm",attrs:{"id":'d1'+ _vm.fieldName,"value":_vm.value,"api-key":"meh3qjtt8e71r37qwbm7ckegbm3e00lmwxfrwqt40hho4tf4","init":{
                height: 250,
                min_height: 150,
                menubar: true,
                toolbar2: 'undo redo | fontsizeselect ' +
                  'formatselect | bold italic  strikethrough  forecolor backcolor ' +
                  '| link | alignleft aligncenter alignright alignjustify  ' +
                  '| numlist bullist outdent indent  | removeformat' +
                  '| code | preview fullscreen ',
                plugins: [
                  'advlist autolink template lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                templates: _vm.editorTemplates,
              }},on:{"input":_vm.onEditorInputChange}}):_vm._e()],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }